<template>
  <div class="chats">
    <div
      v-for="(eachMessage, index) in messages"
      :key="`${eachMessage.id}-${index}`"
      class="chat"
      :class="{ 'chat-left': eachMessage.ownerId !== userData.id }"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="secondary"
          :src="eachMessage.owner ? eachMessage.owner.avatar : ''"
        >
          <feather-icon
            v-if="!eachMessage.owner || !eachMessage.owner.avatar"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
      </div>
      <div class="chat-body">
        <div
          :key="eachMessage.id"
          class="chat-content"
        >
          <p>{{ eachMessage.content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { BAvatar } from 'bootstrap-vue';
import { getUserData } from '@/auth/utils';
import store from '@/store';

export default {
  components: {
    BAvatar,
  },
  props: {
    conversation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      messages: [],
    };
  },
  watch: {
    conversation(newVal) {
      this.getChatLog(newVal);
    },
  },
  created() {
    this.getChatLog();
  },
  methods: {
    getChatLog(val) {
      // eslint-disable-next-line no-nested-ternary
      store.dispatch('app-chat/getConversationMessages', { filter: { conversationId: val && val.id ? val.id : (this.conversation && this.conversation.id && this.conversation.id > 0) ? this.conversation.id : null } }).then(response => {
        this.messages = response.data.items;
      });
    },
  },
  setup() {
    const userData = getUserData();

    return {
      userData,
    };
  },
  // sockets: {
  //   newMessage(newMessageData) {
  //     if (newMessageData && this.conversation && this.conversation.id === newMessageData.conversationId) {
  //       this.messages.push(newMessageData);
  //     }
  //   },
  // },
};
</script>

<style></style>
